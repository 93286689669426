import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { RESPONSE_TYPE, USER_DATA_ROLE, USER_STATUS_DROPDOWN, USER_TYPE_DROPDOWNS } from '../../constants/Constants';
import { ToastService } from '../../services/toast.service';
import { SelectableDropdownComponent } from '../selectable-dropdown/selectable-dropdown.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {

  @Input() type;
  @Input() locationDrop;
  @Input() userData;

  userDropdown = USER_TYPE_DROPDOWNS;
  userStatus = USER_STATUS_DROPDOWN;
  form : FormGroup;
  valueExist;
  select;
  tempLocation;
  tempFormRequest = {};
  displaySelected: any;
  constructor(private modalCtr: ModalController,private fb: FormBuilder,
              private commonService: CommonService,
              private toastService: ToastService,
              private popoverCtrlr: PopoverController) { 
    this.form = this.fb.group({
      firstName:['',Validators.required],
      lastName:['',Validators.required],
      wwid:['',Validators.required],
      email:['',[Validators.email,Validators.required]],
      contactNumber:['',[Validators.required,Validators.maxLength(15)]],
      supervisorWwid:[''],
      locationName:['',Validators.required],
      locationCode:['',[Validators.required,Validators.maxLength(3)]],
      divisionCode:['',[Validators.required,Validators.minLength(3),Validators.maxLength(3)]],
      companyCode:['',[Validators.required,Validators.minLength(3),Validators.maxLength(3)]],
      roles:['',Validators.required],
      allowCompanyChanges:[''],
      isActive:['',Validators.required]
    });
  }

  ngOnInit() {
    if(this.type == 'edit'){
      this.form.patchValue(this.userData);
      this.locationDrop.map(element => {
        this.valueExist = element.label.indexOf(this.userData.locationName);
        if(this.valueExist !== -1){
          this.tempLocation = element.value;
          this.displaySelected = element.label;
          return;
        }
      });
      this.form.controls['companyCode'].setValue(this.tempLocation.split('-')[0])
      this.form.controls['divisionCode'].setValue(this.tempLocation.split('-')[1]) 
      this.form.controls['locationCode'].setValue(this.tempLocation.split('-')[2]) 

      this.form.controls['locationName'].setValue(this.tempLocation)
      // this.select = this.form.controls['roles'].value;
      console.log(this.select)
    }else{
      this.form.controls['isActive'].setValue('Active');
    }
  }

  changeHandle(e){
    // console.log(e)
    this.form.controls['divisionCode'].setValue(e.detail.value.split('-')[1]) 
    this.form.controls['companyCode'].setValue(e.detail.value.split('-')[0])
    this.form.controls['locationCode'].setValue(e.detail.value.split('-')[2]) 
  }

  async cancel() {
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss({
      closeModal:closeModal,
    });
  }

  async selectableDropdown(e:Event,type){
    const popover = await this.popoverCtrlr.create({
      component: SelectableDropdownComponent,
      componentProps: {
        locationsList : this.locationDrop,
        type: type
      },
      event: e,
      side: 'bottom',
      alignment: 'center',
      size: 'auto',
      showBackdrop: false,
      backdropDismiss: true,
    });
    popover.onDidDismiss().then(e=>{
      console.log(e);

      if(e.role !== 'backdrop'){
        this.form.controls['locationName'].setValue(e.data?.location.value);
        this.displaySelected = e.data?.location.label;

      }
    })
      .catch((err) => {
        throw new Error(err);
      });
    return await popover.present();
  }

  validateAllFormFields(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  update(){
    this.validateAllFormFields(this.form);
    if(typeof(this.type) !== 'undefined'){
      if(this.type == 'add'){
        this.addUser();
      } else if(this.type == 'edit'){
        this.updateUser();
      }
    }
  }

  doStuff(){
    this.tempFormRequest = this.form.getRawValue();
    if(this.type == 'add'){
      this.form.removeControl('isActive');
    }
    else{
      let active = this.userStatus.filter(e=> this.form.controls['isActive'].value == e.label);
      this.tempFormRequest['isActive'] = active[0]['value'];
    }
    this.tempFormRequest['contactNumber'] = this.form.controls['contactNumber'].value.toString();
    // this.locationDrop.map(e => {
    //   if(e.value.indexOf(this.form.controls['locationName'])){
    //     this.tempLocation = e;
    //     this.tempFormRequest['locationName'] = e.label.split('(')[0];
    //   }
    // });
    let filterLocation = this.locationDrop.filter(e => e.value == this.form.controls['locationName'].value);
    this.tempFormRequest['locationName'] = filterLocation[0].label.split('(')[0];
    let temp = this.form.controls['roles'].value;
    temp = temp.map(e=>USER_DATA_ROLE[e]);
    this.tempFormRequest['roles'] = temp;
  }

  errorProcess(e){
    if(e?.errorCode == 'USER_DUPLICATE_RECORD')
        this.toastService.setToast('User already exists ',RESPONSE_TYPE.ERROR);
      else
        this.toastService.setToast(`Error occurred while ${this.type == 'add' ? 'adding user' : 'updating user detail'}`,RESPONSE_TYPE.ERROR);  
  }

  addUser(){
    this.doStuff();    
    this.commonService.addUser(this.tempFormRequest).pipe(
    catchError( e => {
      this.errorProcess(e);
      return throwError(e);
    })
    ).subscribe({ next:(res)=>{
      console.log(res);
      this.toastService.setToast('User has been added successfully',RESPONSE_TYPE.SUCCESS);
      this.closeModal();
    }});    
  }

  updateUser(){
    this.doStuff();
    // console.log(this.tempFormRequest)
    this.commonService.updateUser(this.tempFormRequest,this.userData?.userId).pipe(
      catchError( e =>{
        this.errorProcess(e);
        return throwError(e);
      })
    ).subscribe({ next:(res)=>{
      console.log(res);
      this.toastService.setToast('User details has been updated successfully',RESPONSE_TYPE.SUCCESS);
      this.closeModal();
    }});  
  }

  async closeModal(){
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss({
      closeModal:closeModal,
      isSuccess:true 
    });
  }



}
