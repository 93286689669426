import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StepHeaderService {

  STEP_HEADER_URL = `${environment.inspectionApiUrl}/step/header/list`;
  SAVE_HEADER_URL = `${environment.inspectionApiUrl}/step/header/save`;
  UPDATE_HEADER_URL = `${environment.inspectionApiUrl}/step/header/update`;

  STEP_DETAIL_LIST_URL = `${environment.inspectionApiUrl}/step/detail/list`;
  STEP_DETAIL_HEADER_NAME = `${environment.inspectionApiUrl}/dropdown/step/header/name`;
  SAVE_DETAIL_URL = `${environment.inspectionApiUrl}/step/detail/save`;
  UPDATE_DETAIL_URL = `${environment.inspectionApiUrl}/step/detail/update`;
  INSPECTION_DROPDOWN_URL = `${environment.inspectionApiUrl}/dropdowns/static`;


  constructor(private http: HttpClient) { }

  getStepHeader(request){
    return this.http.post(this.STEP_HEADER_URL,request);
  }

  saveStepHeader(request1){
    return this.http.post(this.SAVE_HEADER_URL,request1)
  }

  updateHeader(request2,Id){
    let UPDATE_HEADER_URL = this.UPDATE_HEADER_URL+`/${Id}`;
    return this.http.put(UPDATE_HEADER_URL,request2);
  }

  getStepDetail(request3){
    return this.http.post(this.STEP_DETAIL_LIST_URL,request3);
  }

  getStepDetailHeader(){
    return this.http.get(this.STEP_DETAIL_HEADER_NAME);
  }

  saveStepDetail(request4){
    return this.http.post(this.SAVE_DETAIL_URL,request4)
  }

  updateStepDetail(request5,Id1){
    return this.http.put(this.UPDATE_DETAIL_URL+`/${Id1}`,request5);
  }

  // getInspectionDropDown(){
  //   return this.http.get(this.INSPECTION_DROPDOWN_URL);
  // }

}
