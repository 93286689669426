import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  pageName: string = '';
  year:string = '';

  constructor() { 
    this.year = '';
  }

  ngOnInit() {
    this.year= new Date().getFullYear().toString();
   }


}
