import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { CommonAppService } from './services/app.service';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  activePageTitle = 'Home';
  isMenuOpen: boolean = true;
  isVisible: boolean = true;
  userDetail: any;
  isAdmin: boolean = false;
  isLoggedIn: boolean = false;
  url: any;
  tableData: any = "";

  theme:any;
  showRed: boolean = false;
  showLightRed: boolean = false;
  showGreen: boolean = false;
  showPurple: boolean = false;
  showDark: boolean = false;
  showGeometric:boolean=false;
  showLight:boolean=false;
  role: any;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private menu: MenuController,
    private commonService: CommonAppService,
    private authService: AuthService
  ) {

  }

  ngOnInit() {
    const themeVal = localStorage.getItem('theme');
    if(themeVal) {
      document.body.setAttribute('color-theme', themeVal);
    } else {
      document.body.setAttribute('color-theme', 'red');
    }
  }

}