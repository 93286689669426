import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StepHeaderService } from 'src/app/page/step-header-detail-&-list/step-header.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { DROPDOWN_ENTRIES, INSPECTION_TYPE_DROPDOWNS, RESPONSE_TYPE } from '../../constants/Constants';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-header-list',
  templateUrl: './header-list.component.html',
  styleUrls: ['./header-list.component.scss'],
})
export class HeaderListComponent implements OnInit {

  @Input('type') type;
  @Input() editData?;
  @Input() headerName?;
  form: FormGroup;
  form1: FormGroup;
  display;
  data: any=[{}];
  inspectionDropdown;
  headerSelected;
  constructor(private fb: FormBuilder, private modalCtrl: ModalController,
              private stepHeaderService: StepHeaderService,
              private toastService: ToastService,
              private storageService: StorageService) { 
    this.form = this.fb.group({
      'headerName':['',Validators.required],
      'srt':[''],
      'sequence':['',Validators.required],
      'inspectionType':[[],Validators.required],
      'fullHeaderName':['',Validators.required]
    });
    this.form1 = this.fb.group({
      'headerName':['',Validators.required],
      'srt':['',Validators.required],
      'sequence':['',Validators.required],
      'detailName':['',Validators.required],
      'headerId':'',
    });
  }

  ngOnInit() {
    this.inspectionDropdown = this.storageService.getObjectFromStorage(DROPDOWN_ENTRIES.INSPECTION_TYPE);

    if(this.type == 'edit'){
      this.form.patchValue(this.editData)
      this.form.controls['fullHeaderName'].disable({onlySelf: true});
    }
    else if( this.type == 'detailEdit'){
      this.form1.patchValue(this.editData);
      this.display = this.form1.controls['headerName'].value
    }
    else if( this.type == 'detailAdd'){
      this.display = ''
    }

  }

  changeValue(a){
    this.form1.controls['headerName'].setValue(a.detail.value.label);
    this.display = a.detail.value.label
    this.form1.controls['headerId'].setValue(a.detail.value.value)
  }

  async addDetail() {
    const closeModal: string = "Modal Closed";
    await this.modalCtrl.dismiss({
      closeModal:closeModal,
      data: this.data
    });
  }

  saveStepHeader(temp){

    let tempRequest = 
        {
          "headerName": temp?.headerName,
          "srt": temp?.srt,
          "sequence":  temp?.sequence,
          "inspectionType": temp?.inspectionType,
          "fullHeaderName": temp?.fullHeaderName
        }

    this.stepHeaderService.saveStepHeader(tempRequest).pipe(
      catchError(err => {
       
       if(err?.error?.errorCode == 'DATA_SAVE_ERROR'){
        this.toastService.setToast('Error occurred while adding record', RESPONSE_TYPE.ERROR);
      }
      else if(err?.error?.errorCode == "DATA_DUPLICATE_RECORD" ){
          this.toastService.setToast('Data already exists',RESPONSE_TYPE.ERROR); 
      }
        return throwError(err);
      })
    ).subscribe({
      next:(res) =>{
        if('insertId' in res  ){
          this.toastService.setToast('Header added successfully',RESPONSE_TYPE.SUCCESS);
        }
        this.add();
      }
    });
  }

  updateStepHeader(arg){
    let temp = {};
    temp = 
    {
      "headerName": arg?.headerName,
      "srt": arg?.srt,
      "sequence":  arg?.sequence,
      "inspectionType": arg?.inspectionType
    }
    this.stepHeaderService.updateHeader(temp,this.editData?.headerId).pipe(
      catchError(err => {
        if(err?.error?.errorCode == "DATA_DUPLICATE_RECORD" ){
          this.toastService.setToast('Data already exists',RESPONSE_TYPE.ERROR); 
        } else if(err?.error?.errorCode == 'DATA_SAVE_ERROR'){
          this.toastService.setToast('Error occurred while adding record', RESPONSE_TYPE.ERROR);
        }
        return throwError(err);
      })
    ).subscribe({
      next:(res)=>{
        this.toastService.setToast('Header updated successfully',RESPONSE_TYPE.SUCCESS);
        this.add();
      }
    });
  }

  addOrEdit(){
    for (const key in this.form.controls) {
      this.data[key] = this.form.controls[key].value;
    }
    if(this.type == 'add'){
      this.saveStepHeader(this.data);
    }
    else if(this.type == 'edit'){
      this.updateStepHeader(this.data);
    }
  }

  detailAddOrEdit(){
    for (const key in this.form1.controls) {
      this.data[key] = this.form1.controls[key].value;
    }
    if(this.type == 'detailAdd'){
      this.saveStepDetail(this.data);
    } else if(this.type == 'detailEdit'){
      this.updateStepDetail(this.data,this.editData?.detailId);
    }
  }

  saveStepDetail(temp) {

    let tempRequest =
    {
      "headerId": temp?.headerId,
      "srt": temp?.srt,
      "sequence": Number(temp?.sequence),
      "detailName": temp?.detailName

    }
    this.stepHeaderService.saveStepDetail(tempRequest).pipe(
      catchError(err => {
        if(err?.error?.errorCode == 'DATA_DUPLICATE_RECORD' ){
          this.toastService.setToast('Data already exists',RESPONSE_TYPE.ERROR); 
        } else if( err?.error?.errorCode == 'DATA_SAVE_ERROR'){
          this.toastService.setToast('Error occurred while adding record', RESPONSE_TYPE.ERROR);
        }
        return throwError(err);
      })
    ).
      subscribe({
        next: (res) => {
          if ('insertId' in res) {
            this.toastService.setToast('Header added successfully', RESPONSE_TYPE.SUCCESS);
          } 
          this.add();
        }});
  }

  updateStepDetail(req, detailId) {
    let tempRequest =
    {
      "headerId": req?.headerId,
      "srt": req?.srt,
      "sequence": Number(req?.sequence),
      "detailName": req?.detailName
    }
    this.stepHeaderService.updateStepDetail(tempRequest, detailId).pipe(
      catchError(err => {
        if(err?.error?.errorCode == 'DATA_SAVE_ERROR'){
          this.toastService.setToast('Error occurred while adding record', RESPONSE_TYPE.ERROR);
        }else if(err?.error?.errorCode == "DATA_DUPLICATE_RECORD" ){
          this.toastService.setToast('Data already exists',RESPONSE_TYPE.ERROR); 
        } 
        return throwError(err);
      })
    ).subscribe({
      next: (res) => {

        if ('insertId' in res) {
          this.toastService.setToast('Header updated successfully', RESPONSE_TYPE.SUCCESS);
        } 
        this.add();

      }
    })

  }

  async add() {
    const closeModal: string = "Modal Closed";
    await this.modalCtrl.dismiss({
      closeModal:closeModal,
      success: true
    });
  }

  async cancel(){
    await this.modalCtrl.dismiss();
  }

}
