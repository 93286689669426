import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastController: ToastController) {

     }

    setToast(message:string,type:string){
        this.presentToast(message,type)
    }
    
    async presentToast(message:any,type:any) {
        const toast = await this.toastController.create({
            message: message,
            duration: 3000,
            cssClass: type,
            position:'top',
            buttons: [
                {
                    icon: 'close',
                    role: 'cancel'
                }
            ],
        });

        await toast.present();
    }

}