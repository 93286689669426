import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
// import { dismiss } from '@ionic/angular/node_modules/@ionic/core/dist/types/utils/overlays';

@Component({
  selector: 'app-selectable-dropdown',
  templateUrl: './selectable-dropdown.component.html',
  styleUrls: ['./selectable-dropdown.component.scss'],
})
export class SelectableDropdownComponent implements OnInit {

  @Input() locationsList?: any;
  @Input() woList?: any;
  @Input() type!:string;
  tempLocationList = [];
  text:string='';
  tempWO = [];

  constructor(private popoverCtrlr: PopoverController) { }

  ngOnInit() {}

  ionViewWillEnter(){
    if(typeof this.locationsList !== 'undefined'){
      this.tempLocationList = this.locationsList
    } else if(typeof this.woList !== 'undefined'){
      this.tempWO = this.woList;
    }
    if(typeof this.type !== 'undefined'){

    }
  }

  filter(input){
    if(this.type === 'location'){
      this.tempLocationList = this.locationsList.filter(e=> e.label.toLowerCase().indexOf(input.toLowerCase()) !== -1)
    }else{
      this.tempWO = this.woList.filter(e=> e.orderRefNumber.toString().indexOf(input.toString()) !== -1);
    }
  }

  async selectLabel(location){
    await this.popoverCtrlr.dismiss({
        location: location,
    });
  }

  async selectWO(wo){
    await this.popoverCtrlr.dismiss({
        workOrder: wo
    });
  }

}
