import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RoleConfig } from '../guards/role.config';
import { Tokens } from '../models/Tokens';
import { TokenUserInfo } from '../models/TokenUserInfo';
import { User } from '../models/User';
import { CommonService } from 'src/app/services/common.service';
import { LanguageService } from './lang-locale.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  USER_INFO_URL = `${environment.inspectionApiUrl}/user/info`;

  HOME_PAGE_URL = '/work-order-list';
  UNAUTHORIZED_URL = '/unauthorized';

  private isLoggedIn = false;
  private tokenSubject = new BehaviorSubject<Tokens>(null);
  private userSubject = new BehaviorSubject<User>(null);
  private userTokenSubject = new BehaviorSubject<User>(null);
  public siteMaster = null;

  // getting valid list of role Id that web application supports
  private validRoleList = Object.keys(RoleConfig);

  constructor(private http: HttpClient, private router: Router, private storageService: StorageService, private commonService: CommonService,
    private languageService: LanguageService) { }

  private setIsLoggedIn(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
    this.storageService.setObjectInStorage('isLoggedIn', isLoggedIn.toString());
  }

  public setUserInfo(user: User) {
    this.userSubject.next(user);
  }

  private setUserTokenInfo(user) {
    this.userTokenSubject.next(user);
  }

  public getUserTokenInfo() {
    return this.userTokenSubject.getValue();
  }
  
  private setTokens(tokens: Tokens) {
    this.tokenSubject.next(tokens);
  }

  public updateTokens(accessToken: string, idToken: string) {
    const tokens: Tokens = this.tokenSubject.getValue();
    const storedToken = this.storageService.getObjectFromStorage('token');
    if (idToken && idToken != null && idToken.trim().length > 0) {
      tokens.idToken = idToken;
      storedToken.idToken = idToken;
    }

    if (accessToken && accessToken != null && accessToken.trim().length > 0) {
      tokens.accessToken = accessToken;
      storedToken.accessToken = accessToken;
    }

    this.tokenSubject.next(tokens);
    this.storageService.setObjectInStorage('token',storedToken);
  }

  public setUserLanguage(lang = null) {
    /* Setting up user prefered language- Start*/
    const user: User = this.getUserInfo();
    if (!(lang && lang != null)) {
      lang = user.preferred_language;
    }
    this.languageService.changeApplicationLocale(lang);
    /* Setting up user prefered language- End*/
  }

  public getAccessToken() {
    let tokens = this.tokenSubject.getValue();
    if (tokens == undefined || tokens == null) {
      tokens = this.storageService.getObjectFromStorage('token');
    }
    const accessToken = (tokens && tokens != null) ? tokens.accessToken : null;
    return accessToken;
  }

  public getIdToken() {
    let tokens = this.tokenSubject.getValue();
    if (tokens == undefined || tokens == null) {
      tokens = this.storageService.getObjectFromStorage('token');
    }
    const idToken = (tokens && tokens != null) ? tokens.idToken : null;
    return idToken;
  }

  public getRefreshToken() {
    let tokens = this.tokenSubject.getValue();
    if (tokens == undefined || tokens == null) {
      tokens = this.storageService.getObjectFromStorage('token');
    }
    const refreshToken = (tokens && tokens != null) ? tokens.refreshToken : null;
    return refreshToken;
  }

  public getUserInfo() {
    return this.userSubject.getValue();
  }

  public getUserInfoObservable() {
    return this.userSubject.asObservable();
  }

  public getSiteMaster() {
    return this.siteMaster;
  }

  public getUserRoleId() {
    const userObj = this.userSubject.getValue();
    const roleId = (userObj && userObj != null) ? userObj.roleId : -1;
    return roleId;
  }

  public isAuthenticated() {
    if (!this.isLoggedIn) {
      const isLoggedin = this.storageService.getObjectFromStorage('isLoggedIn');
      if (isLoggedin) {
        const token = this.storageService.getObjectFromStorage('token');
        this.authorize(token);
      }
      return isLoggedin && isLoggedin != null ? JSON.parse(isLoggedin) : false;
    }
    return this.isLoggedIn;
  }

  public logout() {
    this.setUserInfo(null);
    this.setTokens(null);
    this.setIsLoggedIn(false);
    this.storageService.removeFromStorage('token');
    this.storageService.removeFromStorage('isLoggedIn');
  }

  // Fetch tokens based on code provided in authorization code flow
  public fetchTokensForCode(code: string) {

    if (code && code != null && code.trim().length > 0) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

      const params: HttpParams = new HttpParams().append('grant_type', environment.oauth2.grantType)
        .append('client_id', environment.oauth2.clientId)
        .append('redirect_uri', environment.oauth2.redirectUrl)
        .append('code', code);

      return this.http.post(environment.oauth2.tokenUrl, null, { headers, params });
    }
    return throwError(() => new Error('auth code not provided'));
  }

  /* fetch user details from backend. */
  public fetchUserInfo(wwid) {
    return this.http.post(this.USER_INFO_URL, {wwid: wwid});
  }

  // fetch access token from refresh token.
  public getTokenFromRefreshToken(refreshToken: string) {
    if (refreshToken && refreshToken != null && refreshToken.trim().length > 0) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

      const params: HttpParams = new HttpParams().append('grant_type', 'refresh_token')
        .append('client_id', environment.oauth2.clientId)
        .append('refresh_token', refreshToken);

      return this.http.post(environment.oauth2.tokenUrl, null, { headers, params });
    }
    return throwError(() => new Error('auth code not provided'));
  }

  // Navigate to home page
  public navigateToHomePage() {
    const redirectUrl = this.storageService.getFromLocalStorage('rd');
    if (redirectUrl && redirectUrl != null && redirectUrl.trim().length > 0) {
      const rd = redirectUrl.slice(0);
      this.router.navigate([rd]);
    } else {
      this.router.navigate([this.HOME_PAGE_URL]);
    }
  }

  // Navigate to Unauthorized Page
  public navigateToUnauthorized() {
    this.setIsLoggedIn(false);
    this.setTokens(null);
    this.setUserInfo(null);
    this.storageService.removeFromStorage('token');
    this.storageService.removeFromStorage('isLoggedIn');
    this.router.navigate([this.UNAUTHORIZED_URL]);
  }

  // Authorization logic
  public authorize(tokens) {
    const idToken = tokens.id_token;
    const decodedToken: any = jwtDecode(idToken);

    if (decodedToken && decodedToken != null) {
      // check if the user was authenticated from authorized source
      // If no, then redirect to unauthorized page
      if (decodedToken.aud !== environment.oauth2.clientId) {
        this.navigateToUnauthorized();
        return;
      }

      // create token object and set the extracted information
      const verifiedTokens: Tokens = new Tokens(tokens);
      const tokenUserInfo = new TokenUserInfo(decodedToken);
      verifiedTokens.setTokenUserInfo(tokenUserInfo);

      this.setTokens(verifiedTokens);

      // const roleId= this.commonService.getCurrentUserRole();
      const roleId = Number(this.storageService.getFromStorage('userRole'));



  
      // verify the user with backend
      this.fetchUserInfo(tokenUserInfo.nickname).subscribe(
        (userInfo: any) => {

          // check if the user information is available
          if (userInfo && userInfo != null && userInfo.wwid != null
            && userInfo.wwid.trim().length > 0
            // && this.validRoleList.indexOf(`${userInfo.role_id}`) !== -1
            ) {

            const user: User = new User(userInfo);
            user.setTokenUserInfo(tokenUserInfo);
            if(roleId){
              user.roleId = roleId;
            }
            this.setUserInfo(user);
            // Set application language as per user language preferance
            this.setUserLanguage(user.preferred_language);

            this.setIsLoggedIn(true);
            this.storageService.setObjectInStorage('token',tokens);
            this.storageService.setInLocalStorage('WWID',userInfo.wwid.trim().toUpperCase());
          } else {
            this.navigateToUnauthorized();
          }
        },
        (err) => {
          this.navigateToUnauthorized();
        }
      );
    } 
    else {
      this.navigateToUnauthorized();
    }
  }
}
