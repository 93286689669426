import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  
  //  loadChildren: () => import('./components/dashboard/dashboard.module').then( m => m.DashboardModule)
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./page/user-data/user-data.module').then(m => m.UserDataPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inspection',
    loadChildren: () => import('./page/inspection/inspection.module').then( m => m.InspectionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'work-order-list',
    loadChildren: () => import('./page/work-order-list/work-order-list.module').then( m => m.WorkOrderListPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'welcome', component: WelcomeComponent, pathMatch: 'full' },
  { path: 'unauthorized', component: UnauthorizedUserComponent, pathMatch: 'full' },
  // {
  //   path: 'quote',
  //   loadChildren: () => import('./page/quote/quote.module').then( m => m.QuotePageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'step-header',
    loadChildren: () => import('./page/step-header-detail-&-list/list/step-header.module').then( m => m.StepHeaderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'step-header-detail',
    loadChildren: () => import('./page/step-header-detail-&-list/detail/step-header-detail.module').then( m => m.StepHeaderDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inspection/quote',
    loadChildren: () => import('./page/customer-quote/quote.module').then( m => m.QuotePageModule)
  },
  {
    path: 'customer-quote-acceptance/:id',
    loadChildren: () => import('./page/customer-quote-accept/customer-quote-accept.module').then( m => m.CustomerQuoteAcceptModule)
  },
  // {
    // path: 'mitchell-one/:id',
    // loadChildren: () => import('./page/mitchell-one/mitchell-one.module').then( m => m.MitchellOnePageModule),
    // canActivate: [AuthGuard]
  // },
  {
    path: 'quote/:id',
    loadChildren:() => import('./page/quote/quote.module').then( m => m.QuotePageModule),
    canActivate: [AuthGuard]
  },
  { path: '', pathMatch: 'full', redirectTo: 'welcome' },
  { path: '**', redirectTo: 'welcome' },

 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
