import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class MessageService {


  private message: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private message$ = this.message.asObservable();

  getMessage(){
    return this.message$;
  }

  setMessage(param){
    this.message.next(param);
  }

  
}
