import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalService } from '../modal.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  @Input() tab_data: any;
  elementType;
  correctionLevel;
  value;

  constructor(private modalCtr: ModalController, private router: Router,
    private modalService:ModalService) { }

  ngOnInit() {
    this.value = this.modalService.getQrvalue();
    this.elementType = NgxQrcodeElementTypes.URL;
    this.correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
   }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss(closeModal);
  }

  onTabClick(event) {
    this.router.navigate([`/${event}`]);
    this.close();
  }
}
