import { TokenUserInfo } from './TokenUserInfo';

export class User {
    id: string;
    name: string;
    wwid: string;
    email: string;
    userLocation: string;
    roleId: number;
    roles: Array<number>;
    entities: object;
    preferred_language: string;
    preferred_sites: [];
    locationCode: string;
    companyCode:string;
    divisionCode:string;
    allowChanges:string
    constructor(data: {
        userId: string, firstName: string, lastName: string, wwid: string, userEmail: string, preferredLanguage: string,
        preferred_sites: [], roleId: number, roles: [], entities: object, locationCode: string,
        companyCode:string,
        divisionCode:string,
        allowCompanyChanges:string
    }) {
        this.id = data.userId;
        this.name = data.firstName +' '+ data.lastName;
        this.wwid = data.wwid;
        this.email = data.userEmail;
        this.roleId = data.roleId;
        this.roles = data.roles;
        this.userLocation = data.companyCode+'-'+data.divisionCode+'-'+data.locationCode
        this.allowChanges = data.allowCompanyChanges
        this.preferred_language = data.preferredLanguage;
        this.preferred_sites = data.preferred_sites;
        this.entities = data.entities;
        this.locationCode = data.locationCode;
        this.companyCode = data.companyCode;
        this.divisionCode = data.divisionCode;
    }

    tokenUserInfo: TokenUserInfo;

    setTokenUserInfo(tokenUserInfo: TokenUserInfo) {
        this.tokenUserInfo = tokenUserInfo;
    }

    getTokenUserInfo() {
        return this.tokenUserInfo;
    }

}
