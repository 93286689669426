import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  CREATE_NEW_INSPECTION_URL = `${environment.inspectionApiUrl}/header/save`;

  GET_INSPECTION_URL = `${environment.inspectionApiUrl}/inspection/details`;

  SAVE_COMMENTS_URL = `${environment.inspectionApiUrl}/comments/save`;

  SAVE_COMPONENT_URL = `${environment.inspectionApiUrl}/component/save`;

  SUBMIT_INSPECTION_URL = `${environment.inspectionApiUrl}/inspection/submit`;

  S3_SAVE_URL = `${environment.inspectionApiUrl}/s3/save`;
  
  S3_DELETE_URL = `${environment.inspectionApiUrl}/s3/delete`;

  SAVE_ATTACHMENT_URL = `${environment.inspectionApiUrl}/attachment/save`;

  DELETE_ATTACHMENT_URL = `${environment.inspectionApiUrl}/attachment/delete`;

  DOWNLOAD_ATTACHMENT_URL = `${environment.inspectionApiUrl}/attachment/download`;

  ACTION_INSPECTION_URL = `${environment.inspectionApiUrl}/admin/save`;
   // send Pdf
  SEND_PDF_MAIL = `${environment.inspectionApiUrl}/customer/mail`;
  // Create quote PDF
  GENERATE_QUOTE_PDF = `${environment.inspectionApiUrl}/report/create`;
  // Generating PDF Id
  GET_PDF_ID = `${environment.inspectionApiUrl}/print/id`;

  SEND_TO_BMS = `${environment.inspectionApiUrl}/send-to-bms`;

  CONTACT_DETAIL = `${environment.inspectionApiUrl}/contact/details/update`;  

  constructor(private http: HttpClient) { }

  private tabValue = new BehaviorSubject(1);

  private tabValue$ = this.tabValue.asObservable();

  private status= new BehaviorSubject(null);

  private status$ = this.status.asObservable();

  getTabValue(){
    return this.tabValue$;
  }

  setTabValue(param){
    return this.tabValue.next(param);
  }

  createInspection(request){
    return this.http.post(this.CREATE_NEW_INSPECTION_URL,request);
  }
  getInspection(request){
    return this.http.post(this.GET_INSPECTION_URL,request);
  }
  saveComment(request){
    return this.http.post(this.SAVE_COMMENTS_URL,request);
  }
  saveComponent(request){
    return this.http.post(this.SAVE_COMPONENT_URL,request);
  }
  submitInspection(request){
    return this.http.post(this.SUBMIT_INSPECTION_URL,request);
  }
  S3Save(request){
    return this.http.post(this.S3_SAVE_URL,request);
  }
  S3Delete(request){
    return this.http.post(this.S3_DELETE_URL,request);
  }
  saveAttachment(request){
    return this.http.post(this.SAVE_ATTACHMENT_URL,request);
  }
  deleteAttachment(request){
    return this.http.post(this.DELETE_ATTACHMENT_URL,request);
  }
  downloadAttachment(request){
    if (request.fileName) {
      return this.http.post<any>(this.DOWNLOAD_ATTACHMENT_URL, request).pipe(
        switchMap(url => {
          return this.http.get(url, {
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
          });
        })
      );
    } else {
      return null;
    }
  }
  uploadFile(fileDetails) {

    if (fileDetails.type && fileDetails.file) {
      return this.http.post<any>(this.S3_SAVE_URL, fileDetails).pipe(
        switchMap((presignedUrl: string) => {
          const headers = new HttpHeaders({ 'Content-Type': fileDetails.type, 'Access-Control-Expose-Headers': 'Content-Disposition', });
          return this.http.put(presignedUrl, fileDetails.file, {
            headers,
            responseType: 'text',
            reportProgress: true,
            observe: 'events',
          });
        })
      );
    } else {
      return null;
    }
  }

  setInspectionStatus(value){
    this.status.next(value);
  }

  getInspectionStatus(){
    return this.status$;
  }

  actionInspection(request){
    return this.http.post(this.ACTION_INSPECTION_URL,request);
  }

  sendPDF(request){
    return this.http.post(this.SEND_PDF_MAIL,request);
  }

  sendToBMS(param){
    return this.http.post(this.SEND_TO_BMS,param)
  }

  updateContactDetails(parameter,id){
    return this.http.put(this.CONTACT_DETAIL+`/${id}`,parameter);
  }
}
