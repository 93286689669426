import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonAppService {

  private userNameRefresh = new BehaviorSubject<any>(null);
  userNameRefreshUpdate = this.userNameRefresh.asObservable();

  private tableData = new BehaviorSubject<string>('initial');
  tableDataUpdate = this.tableData.asObservable();

  private updateDetails = new BehaviorSubject<any>('initial');
  dataUpdate = this.updateDetails.asObservable();

  private filterData = new BehaviorSubject<string>('initial');

  private renderer: Renderer2;
  filterDataUpdate = this.filterData.asObservable();


  constructor(rendererFactory: RendererFactory2, private http: HttpClient) {
    this.renderer = rendererFactory.createRenderer(null, null);
   }

  refreshFilterData(newValue) {
    this.filterData.next(newValue);
  }

  updateDashboardUserName(data: any) {
    this.userNameRefresh.next(data);
  }

  updateTableData(tableData: any) {
    this.tableData.next(tableData);
  }

  updateData(data: any) {
    this.updateDetails.next(data)
  }

  changeTheme(value) {
    if(value) {
      localStorage.setItem('theme', value);
    } else {
      localStorage.setItem('theme', 'red');
    }
    
    if (value == 'light-dark') {
      this.renderer.setAttribute(document.body, 'color-theme', 'light-dark');
    } else if (value == 'red') {
      this.renderer.setAttribute(document.body, 'color-theme', 'red');
    } else if (value == 'dark-black') {
      this.renderer.setAttribute(document.body, 'color-theme', 'dark-black');
    } else if (value == 'dark-green') {
      this.renderer.setAttribute(document.body, 'color-theme', 'dark-green');
    } else if (value == 'purple') {
      this.renderer.setAttribute(document.body, 'color-theme', 'purple');
    } else if (value == 'light-green') {
      this.renderer.setAttribute(document.body, 'color-theme', 'light-green');
    } else if (value == 'geometric') {
      this.renderer.setAttribute(document.body, 'color-theme', 'geometric');
    } else if (value == 'light-red') {
      this.renderer.setAttribute(document.body, 'color-theme', 'light-red');
    }
  }
}








