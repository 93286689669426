import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Tokens } from '../shared/models/Tokens';
import { DOCUMENT } from '@angular/common';
import { StorageService } from './storage.service';
import { User } from '../shared/models/User';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenSubject = new BehaviorSubject<Tokens | null>(null);
  currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  currentUserRoleSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  currentSupplierIdSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) public document: any, private storageService: StorageService) {
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.currentUserRoleSubject = new BehaviorSubject<User | null>(null);
    this.currentSupplierIdSubject = new BehaviorSubject<User | null>(null);

  }
  // setInternalUserLogin(checked: string) {
  //   this.storageService.setInStorage('isInternalUserLogin', checked);
  //   let authURL = environment.authentication_url;
  //   this.document.location.href = authURL;
  //   //// console.log()("test")
  // }

  setTokens(tokens: Tokens | null) {
    this.tokenSubject.next(tokens);
  }

  public getUserInfo() {
    return sessionStorage.getItem("userDetails");
  }

  setUserInfo(user: any) {
    this.currentUserSubject.next(user);
    this.storageService.setInStorage('user', JSON.stringify(user));
  }

  getToken() {
    return this.storageService.getFromStorage('token');
  }

  isLoggedIn() {
    //Check if token persists in session storage
    if (this.getToken() === null) {
      return false;
    }

    const token = this.getToken();

    // Check whether the token is expired and return true or false
    if (token) {
      // do someting
    }
    return true;
  }

  setAdmin(admin: string) {
    this.storageService.setInStorage('adminChecked', admin);
  }

}
