import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import * as fs from 'file-saver';
import { COMPONENT_STATUS, INSPECTION_TEST_STATUS, RESPONSE_TYPE } from 'src/app/shared/constants/Constants';
import { ToastService } from 'src/app/shared/services/toast.service';
import { InspectionService } from '../inspection.service';

@Component({
  selector: 'app-inspection-table',
  templateUrl: './inspection-table.component.html',
  styleUrls: ['./inspection-table.component.scss'],
})
export class InspectionTableComponent implements OnInit {

  @Input('component') component: any = []
  @Output('updatedComponent') updatedComponent = new EventEmitter<any>();
  @Output('updateFileUpload') updateFileUpload = new EventEmitter<any>();
  statusList: any[] = []

  displayedLabels = ["Component Description", "Status", "Notes","Part Required", "Add File"];
  style;
  valid: any=true;
  mode:any
  id:any

  constructor(private router: Router, 
    private inspectionService: InspectionService, 
    private alertController: AlertController,
    private toastService:ToastService,) {
    this.statusList = COMPONENT_STATUS;
    this.style = INSPECTION_TEST_STATUS;

  }

  ngOnInit() {
    this.mode = this.router.url.split('/')[2];
    this.id = this.router.url.split('/')[3];
    this.inspectionService.setTabValue(2);
  }

  next() {
    this.valid = true;
    this.component['componentdetails'].forEach(e=> {
      if(e.status == null || e.status == undefined){
        this.valid = false;
        return;
      }
    });
    if(this.valid){
      this.updatedComponent.emit({fileUpload: false,component: this.component});
    } else{
      this.toastService.setToast("Please select all the status values.",RESPONSE_TYPE.ERROR);
    }
  }

  back() {
    this.inspectionService.setTabValue(1);
    this.router.navigate(['inspection',this.mode == 'create' ? 'create' : 'edit',this.id,'header']);
  }

  onFileSelect(event, data) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      let fileData = {
        id: data.componentsId.split('-')[0],
        fileName: file.name,
        componentsId: data.componentsId,
        seqNo: data.seqNo,
        file: file,
        type: file.type,
        fileId: null,
        compId: data.compId,
        compDetailsId: data.compDetailsId
      }
      if (!data.files) {
        data.files = []
      }
      data.files.push(fileData);
    }
  }

  uploadFile(fileData) {
    fileData.isUploading = true
    this.inspectionService.uploadFile(fileData).subscribe({
      next: (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            fileData.isUploading = true
            break;
          case HttpEventType.Response:
            this.saveDocEntry(fileData);
            this.updatedComponent.emit({fileUpload: true,component: this.component});
            fileData.isUploading = false
            break;
        }

      }, error: (err) => {
        fileData.isUploading = false
        this.toastService.setToast("Error Occured While uploading file to S3",RESPONSE_TYPE.ERROR)
      }
    })
  }


  saveDocEntry(fileDetails) {
    this.inspectionService.saveAttachment(fileDetails).subscribe((data) => {
      this.updateFileUpload.emit();
      this.toastService.setToast("Data updated successfully.",RESPONSE_TYPE.SUCCESS);

    }, (err) => {
      this.toastService.setToast("Error Occured While Saving File Details in DB",RESPONSE_TYPE.ERROR)
    });
  }

  deleteFile(file,data) {
    this.inspectionService.S3Delete(file).subscribe({
      next: () => {
        this.inspectionService.deleteAttachment(file).subscribe({
          next: () => {
            data.files = data.files.filter(e=>e.fileName != file.fileName && e.fileId != file.fileId );
            this.toastService.setToast("Data updated successfully",RESPONSE_TYPE.SUCCESS)
          }, error: (err) => {
            this.toastService.setToast("Error Occured While deleting file in DB",RESPONSE_TYPE.ERROR)
          }
        });
      }, error: (err) => {
        this.toastService.setToast("Error Occured While deleting file from S3",RESPONSE_TYPE.ERROR);
      }
    });
  }


  downloadFile(file) {

    this.inspectionService.downloadAttachment(file).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          break;
        case HttpEventType.Response:
          fs.saveAs(event.body, file.fileName);
          setTimeout(() => {
          }, 1500);
      }
    }, (err) => {
      throw new Error(err);
    })
  }

  async deleteAlert(file,data) {
    const alert = await this.alertController.create({
      header: ' Are you sure to delete the file?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            if (file.fileId) {
              this.deleteFile(file,data);
            } else {
              data.files = data.files.filter(e=>e.fileName !=file.fileName && e.fileId == null )
            }
          },
        },
      ],
    });

    await alert.present();
  }
}

