import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {



    setObjectInStorage(key: string, value: object) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    setInStorage(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    getFromStorage(key: any) {
        return sessionStorage.getItem(key);
    }
}
