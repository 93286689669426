import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MitchellOneService {

  constructor(private http: HttpClient) { }
  
  private AUTH_TOKEN_URL = `${environment.mwApiHostUrl}/mw/token`;
  private MITCHELL_ONE_TOKEN_URL = `${environment.MWHostURL}/mitchell1/v1/prodemand/token/inheritancetoken`;
  private MITCHELL_ONE_VEHICLE_QUALIFIER_URL = `${environment.MWHostURL}/mitchell1/v1/prodemand/vehiclequalifier`;
  private INTEGRATION_CLIENT_URL = `${environment.mitchellOneIntegrationUrl}/api/service/v1/discovery?endpoints=vendor/vnd.m1.script.integrationclient.v1`;
  private MITCHELL_ONE_SRT_DATA = `${environment.mwApiHostUrl}/quote/submit`;
  private SAVE_LABOR_DETAILS = `${environment.quoteApiHostUrl}/mitchell1/submit`;


  private _authToken: { access_token: string; expires_on: string; };
  private _token: string | null = '';


  getAuthToken() {
    return this.http.post(this.AUTH_TOKEN_URL, {}, { headers: { skip: "true" } });
  }

  getMitchellOneToken() {
    return this.http.get(this.MITCHELL_ONE_TOKEN_URL);
  }

  getVehicleQualifier(body: any) {
    return this.http.get(this.MITCHELL_ONE_VEHICLE_QUALIFIER_URL, {
      params: { ...body },
    });
  }

  submitSRTData(body: any) {
    return this.http.post(this.MITCHELL_ONE_SRT_DATA, body);
  }

  getIntegrationClient() {
    return this.http.get(this.INTEGRATION_CLIENT_URL)
  }

  saveLaborDetails(request): Observable<any> {
    return this.http.post<any>(this.SAVE_LABOR_DETAILS, request);
  }


  get authToken() {
    return this._authToken;
  }

  set authToken(_authToken: { access_token: string; expires_on: string; }) {
    this._authToken = _authToken;
  }

  get token() {
    return this._token;
  }
  set token(_token: string | null) {
    this._token = _token
  }
}
