import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LANG_CODE } from '../constants/Constants';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    constructor(private translocoService: TranslocoService) {
    }

    changeApplicationLocale(langCode) {
        if (langCode) {
            let localeToChange = langCode;
            const availableLangs: string[] = this.translocoService.getAvailableLangs() as string[];
            if (!(localeToChange && availableLangs && availableLangs != null && availableLangs.indexOf(localeToChange) !== -1)) {
                localeToChange = this.translocoService.getDefaultLang();
                console.warn('Unsupported language. Language unavailable in translocoService, Falling back to default language.');
            }
            this.translocoService.setActiveLang(localeToChange);
            sessionStorage.setItem(LANG_CODE, localeToChange);
        } else {
            console.error('Language Locale is blank');
        }
    }
}
