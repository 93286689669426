import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if(args && args[0]=='full'){
      return value ? moment(value).format("DD-MMM-YYYY hh:mm A") : null;
    }else{
      return value ? moment.utc(value).format("DD-MMM-YYYY") : null;
    }
  }
}
