import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { MessageService } from './message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  messageSeverity: string;
  message: any;
  buttonText1='Back To Home';
  buttonText2='Quote Form';

  constructor(private storageService: StorageService,
    private messageService: MessageService,
    private route: Router) { }
  
  ngOnInit() {
    this.messageSeverity = this.storageService.getFromLocalStorage('messageSeverity');
    this.messageService.getMessage().subscribe( e =>{
      this.message = e;
    });
  }
  
  quoteForm(){
    var id = this.storageService.getFromLocalStorage('webRef');
    var url = '/quote/'+id;
    this.route.navigateByUrl(url);
  }
  
}
