import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private loginService: LoginService, private storageService: StorageService) { }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      this.storageService.setInLocalStorage('rd', state.url);
      if (this.loginService.isAuthenticated()) {
      return true;
    } else {
      window.location.href = `${environment.oauth2.loginUrl}?client_id=${environment.oauth2.clientId}&response_type=${environment.oauth2.responseType}&scope=${environment.oauth2.scope}&redirect_uri=${environment.oauth2.redirectUrl}`; // &state=${new Date().getTime()}`;
      return false;
    }
  }
}
