import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {

  constructor(private router:Router, 
    @Inject(DOCUMENT) private document: any,
     private authService: AuthService,
      private referrence: ChangeDetectorRef,
      private storageService: StorageService) {

  }



  internalUserLogin() {
    this.storageService.setInStorage("UT","USER");
    let rd = this.storageService.getFromLocalStorage('rd')

      rd && rd==null ? this.router.navigate([rd]): this.router.navigate(['work-order-list']);
    
  }

}
