import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  notDetail=[];

  constructor(private router:Router,
              private popCtrl:PopoverController,
              private commonService:CommonService) { }

  ngOnInit() {
    this.commonService.getNotificationValue().subscribe(e=>{
      // console.log(e)
      this.notDetail = e;
    });
  }

  ionViewWillEnter(){
    if(this.notDetail.length <= 0){
      this.getNotification();
  }
}

  getNotification() {
    this.commonService.getNotificationList().subscribe(e=>{
      let val = Object.values(e);
      this.notDetail = [];
      val.forEach(element => {
        this.notDetail.push(element); 
      });
      this.commonService.setNotificationValue(this.notDetail);
    });
  }
  

  async cancel(){
    await this.popCtrl.dismiss();
  }

  setMarkAsRead(id){
    this.commonService.readNotification(id).subscribe({next:(e)=>{
      // console.log(e);
      this.getNotification();

    },error:(err)=>{
      console.log(err)
    }
    });
  }

  navigate(arg){
    this.setMarkAsRead(arg?.notification_id);
    let url = `/inspection/edit/${arg?.source_id}/preview`
    this.router.navigate([url])
    this.cancel();
  }

}  


