import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
// import { debounce } from '@ionic/angular/node_modules/@ionic/core/dist/types/utils/helpers';
import {  debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { INSPECTION_STATUS, RESPONSE_TYPE } from '../../constants/Constants';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements OnInit {

  @Input() comp;
  @Input() currentStatus;
  @Input() userRole;
  inspectionStatus = INSPECTION_STATUS;
  headerNames=[   {"label":"Component Description", "value":"compListQuoteDesc"},
  {"label":"Notes", "value":"notes"},
  {"label":"Parts Quantity", "value":"partsQuantity"},
  {"label":"Parts Price", "value":"partsPrice"},
  {"label":"Labour Hours", "value":"labourHours"},
  {"label":"Labour Rates", "value":"labourRates"},
  {"label":"Total Rates", "value":"totalRates"},
  {"label":"Remarks", "value":"remarks"}
]
  myForm:FormGroup;


  constructor( private fb: FormBuilder,
               private modalCtr: ModalController,
               private quoteService: CommonService,
               private toastService: ToastService
               ) {
   }

  ngOnInit() {

    this.myForm = this.fb.group({
      compListQuoteDesc:['',Validators.required],
      partsQuantity:['',Validators.required],
      partsPrice:['',Validators.required],
      labourHours:['',Validators.required],
      labourRates:['',Validators.required],
      totalRates:['',Validators.required],
      remarks:[''],
      notes:[''],

    })
    let sum =0;
    this.myForm.valueChanges.pipe(
      debounceTime(500),
    ).subscribe(e=>{
      console.log(e)
      if(e.labourHours != null && e.labourHours != undefined && e.labourRates != null && e.labourRates != undefined && e.partsPrice != null && e.partsPrice != undefined && e.partsQuantity != null && e.partsQuantity != undefined 
         && e.labourHours != '' && e.labourRates != '' && e.partsPrice != '' && e.partsQuantity != '')
      {
        sum = (Number(e.labourHours) * Number(e.labourRates)) + (Number(e.partsPrice) * Number(e.partsQuantity));
      }
        this.myForm.get('totalRates').setValue(sum, {emitEvent:false});
    });

  }

  ionViewWillEnter(){
    this.myForm.patchValue(this.comp);
    // console.log(this.comp)
  }

  update(){
    console.log(this.myForm);
    let payload = {
      "id": Number(this.comp?.componentsId?.split('-')[0]),
      "compDetailsId": this.comp?.compDetailsId,
      "compListQuoteDesc": this.myForm.get('compListQuoteDesc').value,
      "partsQuantity": Number(this.myForm.get('partsQuantity').value),
      "partsPrice": Number(this.myForm.get('partsPrice').value),
      "labourHours": Number(this.myForm.get('labourHours').value),
      "labourRates": Number(this.myForm.get('labourRates').value),
      "totalRates": Number(this.myForm.get('totalRates').value),
      "remarks": this.myForm.get('remarks').value
    }
    this.quoteService.updateQuoteRates(payload).subscribe(
      {
        next:e =>{
        console.log(e)
        this.toastService.setToast('Data saved successfully',RESPONSE_TYPE.SUCCESS);
        this.dismiss();
    },error:err=>{
        this.toastService.setToast('Error occurred while updating data',RESPONSE_TYPE.ERROR);
    }
  });
  }

  cancel(){
    this.modalCtr.dismiss();
  }

  dismiss(){
    this.modalCtr.dismiss(
     { apiCall: true})
  }

}
