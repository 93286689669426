/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { User } from '../../shared/models/User';
import {USER_TYPE_DROPDOWNS} from '../../shared/constants/Constants'
import { CommonService } from 'src/app/services/common.service';
import { filter, take, takeLast } from 'rxjs/operators';
interface Document {
  documentMode?: any;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: User;
  role:any
  roles:any;
  selectedValue:any
  isAuthenticating:boolean =true
  constructor(private loginService: LoginService, private router: Router, private storageService: StorageService,
              private commonService: CommonService) {

    const url = window.location.href;

    const codeIdx = url.indexOf('code=');

    if (codeIdx !== -1) {

      const fragment = url.substring(codeIdx);
      const fragmentsMap: any = this.extractKeyValueBasedOnDelimiter(fragment);
      const code = fragmentsMap.code;

      this.loginService.fetchTokensForCode(code).subscribe({
        next: (tokens) => {
          this.loginService.authorize(tokens);
        },
        error: (err) => {
          this.router.navigate(['unauthorized']);
        }
      });

      this.loginService.getUserInfoObservable().pipe(filter(x=> x!=null && x!=undefined),take(1)).subscribe(user => {
        this.user = user;
        if (this.user) {
          if(user.roles.length>1){
            this.roles= USER_TYPE_DROPDOWNS.filter(e=> this.user.roles.indexOf(e.value) != -1)
            this.isAuthenticating = false;
          }else if(user.roles.length == 1){
            // this.isAuthenticating = true
            this.user.roleId = user.roles[0];
            // commonService.setCurrentUserRole(user.roles[0]);
            this.storageService.setObjectInStorage("currentUserRole",JSON.parse(JSON.stringify(user.roles[0])));
            this.loginService.setUserInfo(this.user);
            this.loginService.navigateToHomePage();
          }else{
            this.router.navigate(['unauthorized']);
          }
          }
      })
    }
  }

  ngOnInit(): void {
    this.storageService.getFromStorage('role');
  }

  extractKeyValueBasedOnDelimiter = (str, delimiter = '&') => {
    const map = {};
    if (str && str != null) {
      const fragArray = str.split(delimiter);
      for (const frag of fragArray) {
        const arr = frag.split('=');
        map[arr[0]] = arr[1];
      }
    }
    return map;
  };


  submit(){
    this.user.roleId = this.selectedValue;
    // this.storageService.setObjectInStorage("currentUserRole",this.selectedValue);
    this.commonService.setCurrentUserRole(this.selectedValue);
    this.loginService.setUserInfo(this.user);
    this.loginService.navigateToHomePage();
  }
}
