import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {


  private qrelementType;
  private qrcorrectionLevel;
  private qrvalue = '';
  
  setQrElementType(param){
    this.qrelementType = param;
  }

  setqrcorrectionLevel(param1){
    this.qrcorrectionLevel = param1;

  }
  setQrvalue(param2){
    this.qrvalue = param2;
  }
  getQrElementType(){
    return this.qrelementType;
  }

  getqrcorrectionLevel(){
    return this.qrcorrectionLevel;
  }
  getQrvalue(){
    return this.qrvalue;
  }
}
