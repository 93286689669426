import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DateFormatPipe } from '../../shared/pipes/date-format.pipe';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { MessageComponent } from '../message/message.component';
import { HeaderListComponent } from '../modal/header-list/header-list.component';
import { MenuModalComponent } from '../modal/menu-modal/menu-modal.component';
import { NotificationComponent } from '../modal/notification/notification.component';
import { ModalComponent as QRModalComponent } from '../modal/QR-modal/modal.component';
import { QuoteComponent } from '../modal/quote/quote.component';
import { RejectModalComponent } from '../modal/reject-modal/reject-modal.component';
import { SelectableDropdownComponent } from '../modal/selectable-dropdown/selectable-dropdown.component';
import { UserComponent } from '../modal/user/user.component';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    QRModalComponent,
    MessageComponent,
    DateFormatPipe,
    HeaderListComponent,
    MenuModalComponent,
    RejectModalComponent,
    NotificationComponent,
    UserComponent,
    SelectableDropdownComponent,
    QuoteComponent
  ],
  imports: [
    IonicModule,
    NgxQRCodeModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    TranslocoModule,
    NgxDaterangepickerMd.forRoot(),
    NgxSliderModule,
    NgMultiSelectDropDownModule.forRoot()

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports:[
    HeaderComponent,
    FooterComponent,
    QRModalComponent,
    RouterModule,
    MessageComponent,
    DateFormatPipe,
    HeaderListComponent,
    MenuModalComponent,
    RejectModalComponent,
    NotificationComponent,
    UserComponent,
    SelectableDropdownComponent,
    QuoteComponent
  ]
})
export class SharedModule { }
