import { Component } from '@angular/core';

@Component({
  selector: 'app-unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrls: ['./unauthorized-user.component.scss'],
})
export class UnauthorizedUserComponent  {

  // constructor() { }

  // ngOnInit() {}

}
