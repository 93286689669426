import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from '../../services/storage.service';


@Component({
  selector: 'app-reject-modal',
  templateUrl: './reject-modal.component.html',
  styleUrls: ['./reject-modal.component.scss'],
})
export class RejectModalComponent implements OnInit {

  @Input() type: any;
  @Input() id: any;
  comment='';
  wwid='';
  inspectionId=0;
  contactName: any;
  constructor(private modalCtr: ModalController,private storageService: StorageService) { }

  ngOnInit() {
    this.wwid = this.storageService.getFromLocalStorage('WWID')?.toUpperCase(); 
  }

  ngAfterViewInit(){
    console.log(this.type);
    this.wwid = this.storageService.getFromLocalStorage('WWID')?.toUpperCase(); 
  }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss({
      closeModal:closeModal,
      comment:this.comment,
      contactName: this.contactName
    });
  }

  save(c){
    this.comment = c.target.value;
  }

  async cancel() {
    const closeModal: string = "Modal Closed";
    await this.modalCtr.dismiss({
      closeModal:closeModal,
      comment: ""
    });
  }

}
