import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { CommonService as CommonService1 } from 'src/app/services/common.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { CommonAppService } from '../../services/app.service';
import { LANGUAGES, LANG_CODE, LANG_DROPDOWNS, USER_DATA, USER_DROPDOWNS } from '../../shared/constants/Constants';
import { MenuModalComponent } from '../modal/menu-modal/menu-modal.component';
import { NotificationComponent } from '../modal/notification/notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showRed: boolean = false;
  showLightRed: boolean = false;
  showGreen: boolean = false;
  showPurple: boolean = false;
  showDark: boolean = false;
  showGeometric:boolean=false;
  theme:any;
  role: any;
  url: any;
  isLoggedIn: boolean = false;
  userDetails: any;
  userFirstName: string;
  userLastname: string;
  userNameString: any;
  userName: string;
  userLocation: string;
  user: any;
  dropdownList :[];
  langList: any[];
  selectedLanguage = ''
  userRole: any;
  display: string;
  notificationDetail = [];
  notifyLength : Number;
  selectedOption;

  constructor(
    private router: Router,
    public popoverController: PopoverController,
    private commonService: CommonAppService,
    private loginService:LoginService,
    private storageService:StorageService,
    private translocoService: TranslocoService,
    private modalCtrl: ModalController,
    private commonAPIservice: CommonService1,
    private navContrlr: NavController,

  ) {

    this.langList = LANG_DROPDOWNS;
    this.loginService.getUserInfoObservable().subscribe(user => {
      this.user = user;
      if (this.user) {
        this.userName = this.user.name;
        this.role=this.user.roleId;
        this.userLocation = this.user.userLocation;
        storageService.setInLocalStorage('userName',this.userName);
        storageService.setInStorage('userLocation',this.userLocation);
        storageService.setInStorage('userRole',this.role);
        this.isLoggedIn = true;
        if (this.user.tokenUserInfo) {
          this.dropdownList = USER_DROPDOWNS[this.role];
          this.userRole = USER_DATA[this.role];
          // commonAPIservice.setCurrentUserRole(this.role);
          this.display=this.userName+`,`+this.userRole;
        }
      }
    })

  }

  ngOnInit() {
    let url = window.location.href
    if(!(url.includes('inspection/quote') || url.includes('welcome') || url.includes('login?code') || url.includes('logout'))){
      this.getNotificationDetails();
      this.translocoService.langChanges$.subscribe(language => {
        this.selectedLanguage = LANGUAGES[language]
      })
      this.commonAPIservice.getNotificationValue().subscribe(e=>{
        this.notifyLength = e?.length;
      })
      this.commonAPIservice.getSelectValue().subscribe(e=>{
        // console.log(e)
        this.selectedOption = e;
      })
    }
  }

  ionViewWillEnter(){
    let tempUrl = window.location.href;
    if(!(tempUrl.includes('inspection/quote') || tempUrl.includes('welcome') || tempUrl.includes('login?code') || tempUrl.includes('logout') || tempUrl.includes('mitchell-one') || tempUrl.includes('customer-quote-acceptance'))){
      this.getNotificationDetails();
    }
  }

  getNotificationDetails(){
    this.commonAPIservice.getNotificationList().subscribe(res=>{
      this.notificationDetail = [];
      // console.log(res)
      // console.log(typeof(res))
      let temp = Object.values(res);
      // console.log(typeof(temp))
      temp.forEach(e=>{
        this.notificationDetail.push(e);
      });
      this.commonAPIservice.setNotificationValue(this.notificationDetail);

      // console.log(this.notificationDetail);
    },error=>{
      
    });
  }

  onToggleColorTheme(event) {
    this.commonService.changeTheme(event);
  }

  navigateToUrl(url) {
    window.open(url, "_blank");
  }

  onClick() {
    let myDiv = document.getElementById('homeContainer');
    if(this.role === 'Ionic-Theme-Admin') {
      if(this.url.includes('home')) {
        myDiv.scrollTop = 0;
      } else {
        this.router.navigate(['/admin/home']);
      }

    } else {
      if(this.url.includes('home')) {
        myDiv.scrollTop = 0;
      } else {
        this.router.navigate(['/web/home']);
      }
    }
  }

  async openModal(){

  const modal1 = await this.modalCtrl.create({
    component: MenuModalComponent,
    componentProps: { 
      langList: this.langList,
      dropdownList: this.dropdownList,
      selectedLanguage : this.selectedLanguage,
      display: this.display,
      isLoggedIn: this.isLoggedIn,
      role: this.role
     },
    animated: false,
    id: 'small',
  });
  modal1.onDidDismiss()
    .catch((err) => {
      throw new Error(err);
    });
  return await modal1.present();
}


async openNotify(e:Event){

  const popover = await this.popoverController.create({
    component: NotificationComponent,
    event: e,
    side: 'bottom',
    alignment: 'center',
    size: 'auto',
    showBackdrop: false,
    backdropDismiss: false
  });
  popover.onDidDismiss()
    .catch((err) => {
      throw new Error(err);
    });
  return await popover.present();
}

  logout(): void {
    this.loginService.logout();
    this.storageService.clearFullStorage();
    // tslint:disable-next-line: max-line-length
    window.location.href = `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
  }

  handleChange(e:any){
    if(e.target.value == 'logout'){
      this.logout();
    }else if(e.target.value == 'usersList'){
      this.selectedOption = 'usersList';
      // this.commonAPIservice.setSelectValue('usersList');
      // this.router.navigate(['users']);
      this.navContrlr.navigateRoot(['/users']);
    }else if(e.target.value == 'stepHeaderList'){
      this.selectedOption = 'stepHeaderList';
      // this.commonAPIservice.setSelectValue('stepHeaderList');
      // this.router.navigate(['step-header']);
      this.navContrlr.navigateRoot(['/step-header']);

    }else if(e.target.value == 'stepHeaderDetail'){
      this.selectedOption = 'stepHeaderDetail';
      // this.commonAPIservice.setSelectValue('stepHeaderDetail');
      // this.router.navigate(['step-header-detail']);
      this.navContrlr.navigateRoot(['/step-header-detail']);

    }
  }

  handleLangChange(e:any){
    sessionStorage.setItem(LANG_CODE, e.target.value);
    this.selectedLanguage = LANGUAGES[e.target.value];
    this.translocoService.setActiveLang(e.target.value);
  }

}
