export const DROPDOWN_ENTRIES = {
	SITE: 'SITE',
	LANGUAGES: 'LANGUAGES',
	CURRENCIES: 'CURRENCIES',
	TRANS_TYPE: 'TRANS_TYPE',
	END_USE: 'END_USE',
	FREIGHT: 'FREIGHT',
	PAYMENT_TYPE: 'PAYMENT_TYPE',
	LINE_TYPE: 'LINE_TYPE',
	NON_STOCK_TYPE: 'NON_STOCK_TYPE',
	UOM: 'UOM',
	LOCATION: 'LOCATION',
	STATUS: 'STATUS',
	CURRENT_USER_ROLE:"currentUserRole",
	INSPECTION_TYPE: 'INSPECTION_TYPE'
};
export const USER_DROPDOWNS = {
	1: [
		{ label: 'logout', value: 'logout' }
	],
	2: [
		{ label: 'logout', value: 'logout' }
	],
	3: [
		{ label: 'logout', value: 'logout' }
	],
	4: [
		{ label: 'usersList', value: 'usersList' },
		{ label: 'stepHeaderList', value:'stepHeaderList'},
		{ label: 'stepHeaderDetail', value:'stepHeaderDetail'},
		{ label: 'logout', value: 'logout' },
	]
}

export const INSPECTION_TYPE_DROPDOWNS = [
	{ label: 'GUARDIAN', value: 'GUARDIAN' },
	{ label: 'DIAGNOSTICS', value: 'DIAGNOSTICS' },
	{ label: 'JSA', value: 'JSA' },
]

export const USER_TYPE_DROPDOWNS = [
	{ label: 'Technician', value: 1 },
	{ label: 'Supervisor', value: 2 },
	{ label: 'Parts Manager', value: 3 },
	{ label: 'Super User', value: 4 }
]

export const USER_STATUS = {
	1: 'Active',
	2: 'Inactive'
}

export const USER_STATUS_DROPDOWN = [
	{ label: 'Active', value: 1},
	{ label: 'Inactive', value: 2}
]

export const USER_DATA = {
	1: 'Technician',
	2: 'Supervisor',
	3: 'Parts Manager',
	4: 'Super User'
}

export const USER_DATA_ROLE = {
	'Technician':1,
	'Supervisor':2,
	'Parts Manager':3,
	'Super User':4
}

export const SORT_COLUMN = {
	ID: 'id',
	ORDERREF : 'orderReference',
	STARTDATETIME : 'startDateTime'
}

export const LANG_CODE = 'LANG_CODE';

export const MANUAL_SPINNER_API = [
	'https//:d3hpacuaa4s4hk.cloudfront.net/api/inspections/report/create',
	'https//:d3hpacuaa4s4hk.cloudfront.net/api/inspections/print/id'
]

export const DOTDECIMAL_LANGS = ['en'];

export const COMMADECIMAL_LANGS = ['fr'];

export const DOT = '.';


export const LANG_DROPDOWNS = [
	{ label: 'English', value: 'en' },
	{ label: 'Françias', value: 'fr' }
]

export const LANGUAGES = {
	en: "English",
	fr: "Françias"
}

export const DEFAULT_RESULT_COUNT = 10;

export const RESULT_COUNT_DROPDOWN = [10,25,50,100]

export const INSPECTION_STATUS = {
	1: {
		value: 'Draft',
		style: {
			'color': '#BA75BB',
			'--background': '#F7DFF7',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--box-shadow': 'none',
			'--border-color': '#F7DFF7'
		}
	},
	2: {
		value: 'Pending',
		style: {
			'color': '#BF9C56',
			'--background': '#FFF0D1',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--box-shadow': 'none',
			'--border-color': '#FFF0D1'
		}
	},
	3: {
		value: 'Approved',
		style: {
			'color': '#3FAF68',
			'--background': '#CAF8DB',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--box-shadow': 'none',
			'--border-color': '#CAF8DB'
		}
	},
	4: {
		value: 'Rejected',
		style: {
			'color': '#EB827A',
			'--background': '#FFE5E3',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--box-shadow': 'none',
			'--border-color': '#FFE5E3'
		}
	}, 
	5: {
		value: 'Quote Pending',
		style: {
			'color': '#BF9C56',
			'--background': '#FFF0D1',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--box-shadow': 'none',
			'--border-color': '#FFF0D1'
		}
	}, 
	6: {
		value: 'Quote Accepted',
		style: {
			'color': '#BA75BB',
			'--background': '#E5E9FF',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--box-shadow': 'none',
			'--border-color': '#E5E9FF'
		}
	},
	7: {
		value: 'Quote Rejected',
		style: {
			'color': '#EB827A',
			'--background': '#FFE5E3',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--box-shadow': 'none',
			'--border-color': '#FFE5E3'
		}
	}
}

export const DEFAULT_INSPECTION_STATUS_SEARCH = 8;

export const COMPONENT_STATUS = [
	{label:"Pass",value:"P"},
	{label:"Fail", value :"F"},
	{label:"N/A",value:"N/A"}
]

export const INSPECTION_TEST_STATUS = {
	'P': {
		value: 'Pass',
		style: {
			'color': '#00AF3F',
			'background': '#E0FFEB',
			'textTransform': 'none',
			'border-color': '#00AF3F'
		}
	},
	'F': {
		value: 'Fail',
		style: {
			'color': '#DA291C',
			'background': '#FDE7E5',
			'textTransform': 'none',
			'border-color': '#DA291C'
		}
	},
	'N/A': {
		value: 'N/A',
		style: {
			'color': '#BF9C56',
			'background': '#FFF0D1',
			'textTransform': 'none'
		}
	},
}

export const INSPECTION_PREVIEW_STATUS = {
	'P': {
		value: 'Pass',
		style: {
			'--color': '#00AF3F',
			'--background': '#E0FFEB',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--border-color': '#E0FFEB',
			'--box-shadow': 'none'
		}
	},
	'F': {
		value: 'Fail',
		style: {
			'--color': '#DA291C',
			'--background': '#FDE7E5',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--border-color': '#FDE7E5',
			'--box-shadow': 'none'
		}
	},
	'N/A': {
		value: 'N/A',
		style: {
			'--color': '#BF9C56',
			'--background': '#FFF0D1',
			'pointerEvents': 'none',
			'--textTransform': 'none',
			'--border-color': '#FFF0D1',
			'--box-shadow': 'none'
		}
	},
}

export const QUOTE_DECLINE_ACCEPT_STATUS = {
	'N': {
		value: 'Yes',
		style: {
			'--color': '#00AF3F',
			'--background': '#E0FFEB',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--border-color': '#E0FFEB',
			'--box-shadow': 'none'
		}
	},
	'Y': {
		value: 'No',
		style: {
			'--color': '#DA291C',
			'--background': '#FDE7E5',
			'pointerEvents': 'none',
			'textTransform': 'none',
			'--border-color': '#FDE7E5',
			'--box-shadow': 'none'
		}
	}
}

export const QUOTE_TABLE_HEADER = {
	'Component List Description': {
		style : {
			'width': '45%'
		}
	},
	'Status': {
		style : {
			'width': '15%'
		}
	},
	'Notes': {
		style : {
			'width': '40%'
		}
	}
}

export const RESPONSE_TYPE = {
	SUCCESS:"success",
	ERROR:"error"
}
/* export const MAX_FILE_NAME_CHAR = 100;

 export const UNDERSCORE = '_';

 export const HYPEN_SPACE = ' - ';

 export const DEFAULT_CUREENCY = 'USD';

 export const BLANK_SPACE = ' ';

 export const BLANK_STRING = '';

 export const ALL = 'ALL';

 export const WWID_REGEX = new RegExp(/^[a-zA-Z]{2}\d{3}$/);

 export const EMAIL_REGEX = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);

 export const DEFAULT_LANG_CODE = 'en';

 export const FRENCH_LANG_CODE = 'fr';

 export const DEFAULT_LANG_ID = 1;





 export const DEFAULT_FRACTION_DIGITS = 2;

 export const COMMA = ',';

 export const COMMA_SPACE = ', ';



 export const MSG_DISPLAY_TIME = 15000;

 export const ZERO = 0;

 export const OPEN_ANGULAR_BRACKET = '<';

 export const CLOSED_ANGULAR_BRACKET = '>';

 export const SUMMARY = 'Summary';

 export const MIN_WAGE = 1050.65;

 export const STD_AVG_WORK_HRS = 8.8;
 export const DEFAULT_TRANS_TYPE = 'OE';
 export const DEFAULT_SUB_LOCATION = 'STOCK'
 export const DEFAULT_PICK_UP = 'Y'
 export const USER_TYPE = { CUSTOMER: 'CUSTOMER', CUMMINS_USER: 'USER' }

 export const serverErrorI18nMap = {
 	UNAUTHORIZED_ACTION: 'message.error.unauthorizedAction',
 	UNAUTHORIZED: 'message.error.unAuthPage',
 	INTERNAL_SERVER_ERROR: 'message.error.internalServerError',
 	User Management
 	USER_SAVE_ERROR: 'configuration.userManagement.message.error.userSave',
 	LAST_USER_ERROR: 'configuration.userManagement.message.error.lastUser',
 	Order Entry
 	ORDER_DUPLICATE_RECORD: 'order.orderEntry.message.error.duplicateOrder',
 	CLONE_ORDER_FAILED: 'order.orderEntry.message.error.cloneFail',
 	Add items
 	NO_PRODUCT_CODE_FOUND: 'order.orderEntry.itemsTab.addItemDialog.messages.error.noProductCodeFound',
 	NO_UOM_FOUND: 'order.orderEntry.itemsTab.addItemDialog.messages.error.noUOMFound',
 	ORDER_PROCESSED_AT_BMS: 'order.orderEntry.message.error.orderProcessedAtBMS',
 	ORDER_NOT_FOUND: 'order.orderEntry.message.error.orderNotFound',

 	COMMENT_NOT_FOUND: 'order.orderEntry.message.error.comment',

 	Work Order
 	UNIT_NOT_FOUND: 'work.workOrder.message.error.UNIT_NOT_FOUND',
 	CUSTOMER_NOT_FOUND: "work.workOrder.message.error.CUSTOMER_NOT_FOUND",
 	QUOTE_DUPLICATE_RECORD: "work.workOrder.message.error.QUOTE_DUPLICATE_RECORD",
 	Master Work Order
 	MWO_CWO_DUPLICATE_RECORD: 'work.masterWorkOrder.message.error.MWO_CWO_DUPLICATE_RECORD'
 }

 export const OrderStatus = {
 	DRAFT: { status: 'inspections.orderStatus.draft', backgroundColor: '#FFC107', fontColor: 'black' },
 	Inspection_Under_Review: { status: 'inspections.orderStatus.inspectionUnderReview', backgroundColor: '#DFB906', fontColor: 'black' },
 	OPEN: { status: 'inspections.orderStatus.open', backgroundColor: '#BFB105', fontColor: 'black' },
 	INVOICED: { status: 'inspections.orderStatus.invoiced', backgroundColor: '#9FA904', fontColor: 'white' },
 	APPROVED: { status: 'inspections.orderStatus.approved', backgroundColor: '#008000', fontColor: 'white' },
 	REJECTED: { status: 'inspections.orderStatus.rejected', backgroundColor: '#FF0000', fontColor: 'white' },
 	 PICKING: { status: 'order.orderStatus.picking', backgroundColor: '#609803', fontColor: 'white' },
 	 PICKED: { status: 'order.orderStatus.picked', backgroundColor: '#409002', fontColor: 'white' },
 	 BACK_ORDER: { status: 'order.orderStatus.backOrder', backgroundColor: '#208801', fontColor: 'white' },
 	ALL: { status: 'inspections.orderStatus.all', backgroundColor: '#208801', fontColor: 'white' },
 }

 export const OrderStatusCode = {
 	'Draft': 1,
 	'Submitted to BMS': 2,
 	'Close': 3,
 	'Rejected': 4
 }

 export const WorkOrderStatus = {
 	'OPEN': 'Open',
 	'CLOSED': 'Closed',
 	'INVOICED': 'Invoiced',
 }


 export const SearchWorkOrderStatus = {
 	'OPEN': 'OPEN',
 	'CLOSED': 'CLOSED',
 	'INVOICED': 'INVOICED',
 }


  Work Order

 export const DEFAULT_WO_TRANS_TYPE = 'WO';

 export const DEFAULT_WO_BILL_TYPE = 'CUSTOMER';

 export const SubStatus = [
 	{ label: 'EQUIPMENT AVAILABLE', value: 'EQUIPMENT AVAILABLE' },
 	{ label: 'REPAIR COMPLETE', value: 'REPAIR COMPLETE' },
 	{ label: 'NULL', value: 'NULL' }
 ]


 export const DISPLAY_COLUMNS_HEADER = {
 	'sequenceId': 'Seq No.',
 	'srt': 'SRT',
 	'componentsDescription': 'Component Description'
 }





 export const SESSION_KEYS = {
 	ACTIVE_ROLE: 'activeRole'
 }

 export const Roles = {
 	ADMIN: 2, TECHNICIAN: 1
 }
*/
