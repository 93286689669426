import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    isLoading$ = null;

    apiCallsInProgress = 0;

    constructor() {
        this.isLoading$ = new BehaviorSubject<boolean>(false);
    }

    show() {
        this.isLoading$.next(true);
    }
    hide() {
        this.isLoading$.next(false);
    }

    incApiCalls() {
        ++this.apiCallsInProgress;
        this.updateLoader();
    }

    decApiCalls() {
        --this.apiCallsInProgress;
        this.updateLoader();
    }

    updateLoader() {
        if(this.apiCallsInProgress > 0) {
            this.show();
        } else {
            this.hide();
        }
    }
}
