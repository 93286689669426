import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

   

    setObjectInStorage(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    getObjectFromStorage(key: string) {
        const obj = sessionStorage.getItem(key);
        if (obj && obj != null) {
            return JSON.parse(obj);
        }
        return null;
    }

    setInStorage(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    getFromStorage(key) {
        return sessionStorage.getItem(key);
    }

    removeFromStorage(key) {
        sessionStorage.removeItem(key);
    }

    clearFullStorage() {
        sessionStorage.clear();
    }

    setInLocalStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    getFromLocalStorage(key) {
        return localStorage.getItem(key);
    }

    removeFromLocalStorage(key) {
        localStorage.removeItem(key);
    }

    clearFullLocalStorage() {
        localStorage.clear();
    }
}
