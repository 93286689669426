import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { RESPONSE_TYPE } from 'src/app/shared/constants/Constants';
import { ToastService } from 'src/app/shared/services/toast.service';
import { INSPECTION_STATUS } from '../../../shared/constants/Constants';
import { InspectionService } from '../inspection.service';
@Component({
  selector: 'app-inspection-data-form',
  templateUrl: './inspection-data-form.component.html',
  styleUrls: ['./inspection-data-form.component.scss'],
})
export class InspectionDataFormComponent implements OnInit {

  inspectionSideTab = [];
  componentsList = [];
  selectedComponent: any
  currentIndex = 0;
  webRef: any
  header: any;
  status;
  style: any;
  color = [];
  mode: any
  constructor(private router: Router,
    private inspectionService: InspectionService,
    private activatedRoute: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private toastService: ToastService) { }

  ngOnInit() {
    this.webRef = this.activatedRoute.snapshot.params.id
    this.inspectionService.setTabValue(2);
    this.getInspectionData();
    this.mode = this.router.url.split('/')[2];
  }


  getInspectionData() {
    let payload = {
      id: this.webRef
    }
    this.inspectionService.getInspection(payload).subscribe({
      next: (res) => {
        this.componentsList = res['components'];
        this.header = res['header'];
        this.status = this.header['currentStatus'];
        this.style = INSPECTION_STATUS[this.status].style;
        for (let index = 0; index < this.componentsList.length; index++) {
          this.color[index] = 'color';
        }
        this.componentsList.forEach(e => e.componentdetails.some(el => {
          // var index1 = Number(el.componentsId.split('-')[1]) - 1;
        
          if (el.status == null || el.status == undefined) {
            let ind = this.componentsList.indexOf(e)
            this.color[ind] = 'nocolor';
            return;
          } 
          // else {
          //   this.color[index1] = 'color';
          //   return;
          // }
        }));
        this.inspectionService.setInspectionStatus(this.status);
        this.status = INSPECTION_STATUS[this.status].value;
        this.inspectionSideTab = this.componentsList.map(e => e.componentsDescription);
        this.selectedComponent = this.componentsList[this.currentIndex]

      }, error: (err) => {
        throw new Error(err);
      }
    })
  }

  selectComponent(index) {
    this.currentIndex = index;
    this.selectedComponent = this.componentsList[this.currentIndex];
  }

  updateComponentDetails(data) {
    this.inspectionService.saveComponent(data.component).subscribe({
      next: async (res) => {
        this.componentsList[this.currentIndex] = data.component;
        if (res['message'] == "Component details updated successfully") {
          this.toastService.setToast(res['message'], RESPONSE_TYPE.SUCCESS);
        }
        else {
          this.toastService.setToast("Error occurred while saving details. ", RESPONSE_TYPE.ERROR);
        }
        data.component['componentdetails'].forEach(element => {
          if (element.status == null || element.status == undefined) {
            this.color[this.currentIndex] = "nocolor";
            return;
          } else {
            this.color[this.currentIndex] = "color";
          }
        });
        if (!data.fileUpload) {
          if (this.currentIndex == this.componentsList.length - 1) {
            this.router.navigate(['inspection', this.mode == 'create' ? 'create' : 'edit', this.webRef, 'preview']);
          } else {
            this.currentIndex = this.currentIndex + 1;
            this.selectedComponent = this.componentsList[this.currentIndex];
          }
        }

      }, error: (err) => {
        this.color[this.currentIndex] = this.color[this.currentIndex] == "color" ? "color" : "nocolor";
      }
    })

  }


  fileUploaded(event) {
    this.getInspectionData();
  }

}
