import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, ObservableInput, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LoaderService } from './shared/services/loader.service';
import { LoginService } from './shared/services/login.service';
import { StorageService } from './shared/services/storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MitchellOneService } from './page/mitchell-one/mitchell-one.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private tokenExceptionList = [environment.oauth2.tokenUrl,environment.s3Domain,'/attachments','aRWorkInstructions/','mitchell-one'];
    constructor(private loadService: LoaderService, private loginService: LoginService,
       private storageService: StorageService, private router: Router,private ngxService: NgxUiLoaderService,
       private quoteService: MitchellOneService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


         this.ngxService.start();
         if(window.location.pathname.indexOf('mitchell-one') !== -1){

             const authToken = this.quoteService.authToken;
             if (req.headers.get("skip")) return next.handle(req);
             const authReq = req.clone({
                 setHeaders: {
                     'Content-Type': 'application/json',
                     Authorization: 'bearer ' + authToken?.access_token
                    }
                });
                return next.handle(authReq).pipe(
                    finalize(() => {
                        this.loadService.hide();
                        this.ngxService.stop()
                    })
                );
        }
        else{

            
            const newReq = this.getUpdatedRequest(req);
            
            return next.handle(newReq).pipe(
                catchError(err => {
                    const refreshToken = this.loginService.getRefreshToken();
                    if (err instanceof HttpErrorResponse && err.status === 401 && refreshToken != null) {
                        return this.manage401ErrorCode(req, next, refreshToken);
                    } else if (err instanceof HttpErrorResponse && err.status === 500 && (err.error && err.error.errorCode == "UNAUTHORIZED_ACCESS")) {
                        this.router.navigate(['unauthorized']);
                        return EMPTY;
                    } else if(err instanceof HttpErrorResponse && err.status === 401 && !window.location.href.includes('welcome') && !window.location.href.includes('https://login.microsoftonline.com/') && !window.location.href.includes('login?code')){
                        this.router.navigate(['unauthorized']);
                        return EMPTY;
                    } 
                    else{
                        return this.handleError(err);
                    }
                }),
                finalize(() => {
                    this.loadService.hide();
                    this.ngxService.stop()
                }),
                );
            }
    }

    // add token to header and clone the request
    getUpdatedRequest(req: HttpRequest<any>) {
        const token = this.loginService.getIdToken();

        let headers = req.headers;
        if (headers && headers != null
            && token && token != null
            && !this.isUrlInException(req.url)) {
            headers = headers.set('Authorization', token);
        }

        return req.clone({ headers });
    }

    // Check if the URL is in Exception list
    isUrlInException(url) {
        let isInException = false;

        this.tokenExceptionList.forEach(e => {
            if (e && e != null && url.indexOf(e) !== -1) {
                isInException = true;
                return;
            }
        });

        return isInException;
    }

    // method responsible to manage 401 Unauthorized error.
    // In case of unauthorized error, this logic makes attempt
    // to get new tokens based on refresh token already available.
    manage401ErrorCode(req: HttpRequest<any>, next: HttpHandler, refreshToken: string): ObservableInput<any> {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.loginService.getTokenFromRefreshToken(refreshToken).pipe(
                switchMap((newToken: any) => {
                    if (newToken) {
                        // set token to UserInfo object
                        const { id_token, access_token } = newToken;
                        this.loginService.updateTokens(access_token, id_token);
                        this.tokenSubject.next(id_token);

                        // allow original request process again with new Tokens set in header
                        return next.handle(this.getUpdatedRequest(req));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }));
        } else {
            // this logic holds of any request from processing until the refresh token is retrieved.
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.getUpdatedRequest(req));
                }));
        }
    }


    // Houses logic to execute to force user to logout.
    logoutUser() {
        // Route to the login page
        const status = confirm('Session is timed out. You are required to login again. Please click OK to navigate to login page');
        // if (status) {
            this.logout();
        // }
        return this.handleError('Session Timed Out');
    }

    logout(): void {
        this.loginService.logout();
        this.storageService.clearFullStorage();
        window.location.href = `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
    }

    // Houses logic to handle http error
    handleError(error: any) {
        console.error(error);
        return throwError(error);
    }
}
