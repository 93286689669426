export enum Actor {
  APPROVER = 1,
  ADMIN = 2,
  // SERVICE_ORDER_ADMIN = 3,
  // CUSTOMER = 4
}

const roleRouteMap = {};

roleRouteMap[Actor.APPROVER] = [
  '/web/config/user',
  '/web/inspection/search',
  '/web/inspection/details'

];

roleRouteMap[Actor.ADMIN] = [

  '/web/config/user',
  '/web/inspection/search',
  '/web/inspection/details'
];

export const RoleConfig = roleRouteMap;