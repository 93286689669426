import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  token_type: any = "Bearer";
  isAdmin: any;
  userdata: any;
  authData: any;
  // API_HOST_URL = `${environment.apiHostUrl}/`;
  USER_INFO = `${environment.inspectionApiUrl}`+`/user/info`;
  USER_LIST = `${environment.inspectionApiUrl}`+`/user/list`;
  INSPECTION_LIST_URL = `${environment.inspectionApiUrl}`+`/inspection/list`;
  USER_ADD_URL = `${environment.inspectionApiUrl}` + `/user/info/save`;
  USER_UPDATE_URL = `${environment.inspectionApiUrl}` + `/user/info/update`;
  NOTIFICATION_READ_URL = `${environment.inspectionApiUrl}` + `/notification/read`;
  NOTIFICATION_LIST_URL = `${environment.inspectionApiUrl}`+`/notification/list`;
  GET_QUOTE_URL = `${environment.inspectionApiUrl}/quote/details`;
  SAVE_QUOTE_URL = `${environment.inspectionApiUrl}/save`;
  SAVE_QUOTEFORM_URL = `${environment.inspectionApiUrl}/data/save`;
  SAVE_COMPLETE_QUOTE_URL = `${environment.inspectionApiUrl}/data/save`;
  SUBMIT_QUOTE_TO_BMS_URL = `${environment.inspectionApiUrl}/submit-to-bms`;
  UPDATE_QUOTE_RATES = `${environment.inspectionApiUrl}/quote/line/save`;
  SEND_QUOTE_TO_CUSTOMER = `${environment.inspectionApiUrl}/quote/send`;
  GET_ACCEPT_QUOTE_URL = `${environment.inspectionApiUrl}/quote/accept/details`;
  SAVE_ACCEPT_QUOTE = `${environment.inspectionApiUrl}/quote/accept/action`
  WO_STATUS_LIST = `${environment.inspectionApiUrl}/woStatusList`

  constructor(private http: HttpClient) { }

  private notification = new BehaviorSubject(null);

  private notification$ = this.notification.asObservable();

  private select = new BehaviorSubject(null);

  private select$ = this.select.asObservable();

  private userRole = null;

  setCurrentUserRole(param){
    this.userRole = param;
  }

  sendToCustomer(request){
    return this.http.post(this.SEND_QUOTE_TO_CUSTOMER,request);
  }

  getAcceptQuote(request){
    return this.http.post(this.GET_ACCEPT_QUOTE_URL,request)
  }

  saveAcceptQuote(request){
    return this.http.post(this.SAVE_ACCEPT_QUOTE,request)
  }

  getCurrentUserRole(){
    return this.userRole;
  }

  getNotificationList(){
    return this.http.get(this.NOTIFICATION_LIST_URL);
  }

  getSelectValue(){
    return this.select$;
  }

  setSelectValue(param){
    return this.select.next(param);
  }

  getNotificationValue(){
    return this.notification$;
  }

  setNotificationValue(param){
    console.log(param);
    return this.notification.next(param);
  }

  readNotification(id){
    return this.http.put(this.NOTIFICATION_READ_URL+`/${id}`,{});
  }

  getInspectionQuote(request) {
    // console.log("URLLLL");
    return this.http.post(this.GET_QUOTE_URL, request);
  }

  updateQuoteRates(request){
    return this.http.post(this.UPDATE_QUOTE_RATES,request);
  }

  saveQuoteForm(request) {
    return this.http.post(this.SAVE_QUOTEFORM_URL, request);
  }

  submitQuoteToBMS(request){
    return this.http.post(this.SUBMIT_QUOTE_TO_BMS_URL,request);
  }

  saveInspectionQuote(reqe){
    return this.http.post(this.SAVE_COMPLETE_QUOTE_URL,reqe);
  }

  saveStatus(request1){
    return this.http.post(this.SAVE_QUOTE_URL, request1);
  }

  setTokens(acessToken: any) {
    sessionStorage.setItem('accessToken', acessToken);
  }

  setObjectInStorage(key: string, value: object) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  setIsAdmin(value: any): Observable<any> {
    this.isAdmin = value;
    return this.isAdmin;
  }

  
  userData = [
    {"WWID":"SL714","firstName":"Aqib","lastName":"Siddiqui", "email":"sl714@cummins.com", "contactNumber":"1234567890", "supervisorWwid": "ij980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Regular", "allowCompanyChanges":"Y"},
  {"WWID":"SL715","firstName":"aashritha","lastName":"kumari", "email":"siri@cummins.com", "contactNumber":"8867567890", "supervisorWwid": "ok980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Regular", "allowCompanyChanges":"Y"},
  {"WWID":"Sy797","firstName":"Pradeep","lastName":"Narayan", "email":"pradeep@cummins.com", "contactNumber":"9856439845", "supervisorWwid": "Sy980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"efgh", "role":"Technician", "allowCompanyChanges":"N"},
  {"WWID":"us404","firstName":"Vineesha","lastName":"Palanki", "email":"palanki@cummins.com", "contactNumber":"9861068221", "supervisorWwid": "us980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Admin", "allowCompanyChanges":"Y"},
  {"WWID":"mv283","firstName":"karthik","lastName":"kumar", "email":"karthik@cummins.com", "contactNumber":"9866896534", "supervisorWwid": "iy980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Regular", "allowCompanyChanges":"Y"},
  {"WWID":"us141","firstName":"santanu","lastName":"Mandal", "email":"santanu@cummins.com", "contactNumber":"8756436789", "supervisorWwid": "ok980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Admin", "allowCompanyChanges":"N"},
  {"WWID":"SL724","firstName":"rajdeep","lastName":"more", "email":"rajdeep@cummins.com", "contactNumber":"9857942988", "supervisorWwid": "pv980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Regular", "allowCompanyChanges":"Y"},
  {"WWID":"SL734","firstName":"vanita","lastName":"kumari", "email":"vanita@cummins.com", "contactNumber":"7899669966", "supervisorWwid": "kl980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Technician", "allowCompanyChanges":"Y"},
  {"WWID":"SL744","firstName":"aruna","lastName":"Giri", "email":"aruna@cummins.com", "contactNumber":"1238996644", "supervisorWwid": "kk980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Regular", "allowCompanyChanges":"N"},
  {"WWID":"SL744","firstName":"aruna","lastName":"Giri", "email":"aruna@cummins.com", "contactNumber":"1238996644", "supervisorWwid": "kk980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Regular", "allowCompanyChanges":"N"},
  {"WWID":"SL704","firstName":"vanita","lastName":"kumari", "email":"vanita@cummins.com", "contactNumber":"7899669966", "supervisorWwid": "kl980", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Technician", "allowCompanyChanges":"Y"},
  {"WWID":"us140","firstName":"sanu","lastName":"Mandal", "email":"sanu@cummins.com", "contactNumber":"8756436789", "supervisorWwid": "ok970", "companyCode":"infy","divisionCode":"da","locationCode":"dc", "branchName":"abcd", "role":"Admin", "allowCompanyChanges":"Y"},
  ];

  userRoles = ["Admin","Technician","Regular"]; 

  getUserRoles(){
    return this.userRoles;
  }

  // getUserInfo(wwid){
  //   return this.http.post(this.USER_INFO,wwid);
  // }

  getUserData(){
    return this.userData;
  }

  // getUserDataList(): Observable<Object>{
  //   return this.http.post(this.USER_LIST,{});
  // }

  wo = [{"WO":"20221232342","inspectionType":"Diagnostics","type":"WOQT","startDate":"01/31/2022","custCode":"SO09876","qDecName":"New abcd","techName":"Dilip","cUser":"MT302","status":"Approved"},
  {"WO":"20221232342","inspectionType":"Diagnostics","type":"WOQT","startDate":"01/31/2022","custCode":"SO09876","qDecName":"random","techName":"Kay","cUser":"MT302","status":"Draft"},
  {"WO":"20221576634","inspectionType":"Guardian","type":"WOQT","startDate":"01/31/2022","custCode":"SO09876","qDecName":"Goku","techName":"Sanu","cUser":"MT302","status":"Approved"},
  {"WO":"20221298742","inspectionType":"Diagnostics","type":"WOQT","startDate":"01/31/2022","custCode":"SO09876","qDecName":"Dragon","techName":"Dilip","cUser":"MT302","status":"Quote Accepted"},
  {"WO":"20221232378","inspectionType":"Guardian","type":"WOQT","startDate":"01/31/2022","custCode":"SO09876","qDecName":"Loki","techName":"Dilip","cUser":"KY098","status":"Draft"},
  {"WO":"20221232098","inspectionType":"Diagnostics","type":"WOQT","startDate":"10/29/2022","custCode":"SO09876","qDecName":"asdfg","techName":"Sanu","cUser":"MT302","status":"Approved"},
  {"WO":"20221232209","inspectionType":"Diagnostics","type":"WOQT","startDate":"02/13/2022","custCode":"SO05676","qDecName":"New abcd","techName":"Dilip","cUser":"LO098","status":"Pending"},
  {"WO":"20221238537","inspectionType":"Guardian","type":"WOQT","startDate":"01/09/2022","custCode":"SO09809","qDecName":"New abcd","techName":"Kay","cUser":"KY098","status":"Approved"},
  {"WO":"20221232196","inspectionType":"Diagnostics","type":"WOQT","startDate":"11/11/2022","custCode":"SO09289","qDecName":"New abcd","techName":"Sanu","cUser":"MT302","status":"Approved"},
  {"WO":"20221230387","inspectionType":"JSA","type":"WOQT","startDate":"07/20/2022","custCode":"SO08906","qDecName":"Dragon","techName":"Kay","cUser":"KY098","status":"Rejected"},
  {"WO":"20221290909","inspectionType":"Diagnostics","type":"WOQT","startDate":"09/31/2022","custCode":"SO09670","qDecName":"Ring","techName":"Sanu","cUser":"LO098","status":"Approved"},
  {"WO":"20221233456","inspectionType":"JSA","type":"WOQT","startDate":"07/19/2022","custCode":"SO09490","qDecName":"New abcd","techName":"Dilip","cUser":"MT302","status":"Pending"},
];

  getWOData(){
    return this.wo;
  }

  getWOList(request){

    return this.http.post(this.INSPECTION_LIST_URL,request);
  }

  updateUser(request2,id){
    return this.http.put(this.USER_UPDATE_URL+`/${id}`,request2);
  }

  addUser(request1){
    return this.http.post(this.USER_ADD_URL,request1);
  }

  getWoStatusList(){
    return this.http.get(this.WO_STATUS_LIST)
  }

}








