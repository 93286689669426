import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { LANGUAGES, LANG_CODE } from '../../constants/Constants';
import { LoginService } from '../../services/login.service';
import { StorageService } from '../../services/storage.service';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-menu-modal',
  templateUrl: './menu-modal.component.html',
  styleUrls: ['./menu-modal.component.scss'],
})
export class MenuModalComponent implements OnInit {

  @Input() selectedLanguage;
  @Input() langList;
  @Input() dropdownList;
  @Input() display;
  @Input() isLoggedIn;
  @Input() role;
  detailLength;
  selectedOption: any;
  constructor(private translocoService: TranslocoService,
              private storageService:StorageService,
              private loginService:LoginService,
              private navContrlr: NavController,
              private modalCtrl: ModalController,
              private popCtrl:PopoverController,
              private commonService: CommonService
  ) { }

  ngOnInit() {
    this.translocoService.langChanges$.subscribe(language => {
      this.selectedLanguage = LANGUAGES[language]
    })
    this.commonService.getNotificationValue().subscribe(e=>{
      console.log(e.length)
      this.detailLength = e.length;
    });
    this.commonService.getSelectValue().subscribe(e=>{
      // console.log(e)
      this.selectedOption = e;
    })
  }

  handleChange(e:any){
    if(e.target.value == 'logout'){
      this.logout();
    }else if(e.target.value == 'usersList'){
      this.selectedOption = 'usersList';
      // this.commonAPIservice.setSelectValue('usersList');
      // this.router.navigate(['users']);
      this.navContrlr.navigateRoot(['/users']);
    }else if(e.target.value == 'stepHeaderList'){
      this.selectedOption = 'stepHeaderList';
      // this.commonAPIservice.setSelectValue('stepHeaderList');
      // this.router.navigate(['step-header']);
      this.navContrlr.navigateRoot(['/step-header']);

    }else if(e.target.value == 'stepHeaderDetail'){
      this.selectedOption = 'stepHeaderDetail';
      // this.commonAPIservice.setSelectValue('stepHeaderDetail');
      // this.router.navigate(['step-header-detail']);
      this.navContrlr.navigateRoot(['/step-header-detail']);

    }
  }

  handleLangChange(e:any){
    sessionStorage.setItem(LANG_CODE, e.target.value);
    this.selectedLanguage = LANGUAGES[e.target.value];
    this.translocoService.setActiveLang(e.target.value);
  }

  logout(): void {
    this.loginService.logout();
    this.storageService.clearFullStorage();
    // tslint:disable-next-line: max-line-length
    window.location.href = `${environment.oauth2.logoutUrl}?client_id=${environment.oauth2.clientId}&logout_uri=${environment.oauth2.logoutRedirectUrl}`;
  }

  async cancel(){
    await this.modalCtrl.dismiss();
  }

  async notify(e){

    const popover = await this.popCtrl.create({
      component: NotificationComponent,
      event: e,
      alignment: 'center',
      side: 'bottom',
      size: 'auto',
      showBackdrop: false,
    });
    popover.onDidDismiss()
      .catch((err) => {
        throw new Error(err);
      });
    return await popover.present();
  }
}
