import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Google Tag Manager
const script = document.createElement('script');
script.type = 'text/javascript';
let code = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=1IwWGi2anv0eRpVSv1WYcw&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','"
+ environment.gtmKey +"');";

// below code has separate gtmAuth for diff environment
// let code = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=`
//   + environment.gtmAuth + `';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','`
//   + environment.gtmKey + "');";


script.text = code;
document.body.appendChild(script);
// End Google Tag Manager


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
